/********************************** Popeyes ********************************************************/
@charset "utf-8";

@font-face {
  font-family: "League Spartan";
  src:
    url("./../../../../../../assets/fonts/LeagueSpartan-Medium.woff2")
      format("woff2"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Medium.woff")
      format("woff"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src:
    url("./../../../../../../assets/fonts/LeagueSpartan-Regular.woff2")
      format("woff2"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Regular.woff")
      format("woff"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src:
    url("./../../../../../../assets/fonts/LeagueSpartan-Bold.woff2")
      format("woff2"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Bold.woff")
      format("woff"),
    url("./../../../../../../assets/fonts/LeagueSpartan-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src:
    url("./../../../../../../assets/fonts/LeagueSpartan-SemiBold.woff2")
      format("woff2"),
    url("./../../../../../../assets/fonts/LeagueSpartan-SemiBold.woff")
      format("woff"),
    url("./../../../../../../assets/fonts/LeagueSpartan-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* general start*/

body {
  background: #f3f3f3;

  color: #4d4d4d;

  font-family: "League Spartan";

  font-weight: normal;

  font-style: normal;

  font-size: 16px;

  line-height: 1.6;

  margin: 0;

  padding: 0;
}

sub,
sup {
  font-size: 75%;

  position: relative;

  vertical-align: baseline;
}

sup {
  top: 10px;

  left: 10px;
}

sub {
  bottom: 10px;

  right: 10px;
}

ul {
  margin: 0;

  padding: 0;

  list-style-type: none;
}

ul li {
  padding: 0;

  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;

  margin: 0;

  font-family: "League Spartan";

  font-weight: 600;

  font-style: normal;
}

h1 {
  font-size: 50px;

  line-height: 1.2;

  color: #222222;

  font-weight: bold;
}

h2 {
  font-size: 40px;

  line-height: 1.2;

  color: #222222;

  font-weight: bold;
}

h3 {
  font-size: 26px;

  line-height: 1.2;

  color: #222222;
}

h4 {
  font-size: 20px;

  line-height: 1.2;

  color: #222222;
}

h5 {
  font-size: 16px;

  line-height: 1.3;

  color: #222222;
}

p {
  padding: 0 0 20px 0;

  margin: 0;
}

a {
  -webkit-transition: all 0.2s linear;

  -moz-transition: all 0.2s linear;

  -o-transition: all 0.2s linear;

  transition: all 0.2s linear;

  color: #0396a0;

  outline: none;

  text-decoration: none;
}

a:hover,
a:focus {
  color: #000;

  -webkit-transition: all 0.2s linear;

  -moz-transition: all 0.2s linear;

  -o-transition: all 0.2s linear;

  transition: all 0.2s linear;

  text-decoration: none !important;

  outline: none !important;
}

a > img {
  line-height: 0;
}

select,
button,
input {
  box-shadow: none;

  outline: none;
}

select:hover,
button:hover,
input:hover,
select:focus,
button:focus,
input:focus {
  box-shadow: none;

  outline: none;
}

select {
  -webkit-appearance: none;

  -moz-appearance: none;

  -ms-appearance: none;

  -o-appearance: none;

  appearance: none;

  /* background-image: url(./../../../../../../../../../../assets/images/select-arrow.png) !important; */

  background-repeat: no-repeat;

  background-position: 95% center;
  background-size: inherit !important;
}

.slick-loading .slick-list {
  /* background: #fff url('./../../../../../assets/ajax-loader.gif') center center no-repeat; */
}

/* @font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('fonts/slick.eot');
    src: url('fonts/slick.eot?#iefix') format('embedded-opentype'), url('fonts/slick.woff') format('woff'), url('fonts/slick.ttf') format('truetype'), url('fonts/slick.svg#slick') format('svg');
} */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.modal-dialog .modal-body {
  padding: 2rem;
}

.modal-dialog .modal-body main {
  max-height: 70vh;
  overflow-y: auto;
}

.image-checked {
  border: 2px solid;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.btn_submit,
input[type="submit"],
input.btn_submit[type="submit"] {
  background: #1b1b1b;

  color: #fff;

  border: 1px solid #1b1b1b;

  border-radius: 4px;

  font-family: "League Spartan";

  font-weight: 500;

  font-style: normal;

  font-size: 16px;

  line-height: 18px;

  padding: 10px 30px;

  display: inline-block;

  -webkit-transition: all 0.2s linear;

  -moz-transition: all 0.2s linear;

  -o-transition: all 0.2s linear;

  transition: all 0.2s linear;
}

.btn_submit:hover,
input[type="submit"]:hover,
input.btn_submit[type="submit"]:hover,
.btn_submit:focus,
input[type="submit"]:focus,
input.btn_submit[type="submit"]:focus {
  background: #0396a0;

  border: 1px solid #0396a0;

  color: #fff;

  outline: 0;

  -webkit-transition: all 0.2s linear;

  -moz-transition: all 0.2s linear;

  -o-transition: all 0.2s linear;

  transition: all 0.2s linear;
}

header.header {
  background-color: #ffff;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  transition: all 300ms;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
}

.header.fixed {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.header .form-control {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-color: #ff7c00;
  color: #ff7c00;
}

header .form-select {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #ff7c00;
  color: #fff;
  border: 0;
  padding-left: 15px;
  padding-right: 15px;
}

footer {
  background-color: #1e1e1e;
}

.strong {
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.row-5 {
  margin-left: -5px;

  margin-right: -5px;
}

.bgred {
  background-color: #d62b2b;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-no-allowed {
  cursor: not-allowed;
}

.cursor-none {
  cursor: none;
  pointer-events: none;
}
label {
  margin-bottom: 5px;
}

.form-control {
  height: auto;

  padding: 9px 10px;

  font-size: 14px;
}

.form-select:focus {
  border-color: none;

  outline: 0;

  box-shadow: none;
}

.form-control:focus {
  color: #222;

  background-color: #fff;

  border-color: none;

  outline: 0;

  box-shadow: none;
}

.form-control::placeholder {
  opacity: 1;

  color: #ccc;
}

.form-group {
  margin-bottom: 10px;
}

.socila-media-results .btn1 {
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  padding: 11px 45px;
  font-weight: 600;
  border-radius: 0;
  text-align: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #ff7c00;
  color: #fff;
}

.socila-media-results .btn1:hover {
  background-color: #ce6707;
  color: #fff;
}

.searchbar .form-control {
  border-color: #ff7b0086;
  border-width: 3px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  font-size: 20px;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.reviewbox {
  font-size: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.reviewsec {
  max-height: 450px;
  overflow-y: auto;
}

.reviewsec .row .col-md-6:first-child .reviewbox h4 {
  color: #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox h4 {
  color: #f10202;
}

.reviewsec .row .col-md-6:first-child .reviewbox .btn1 {
  color: #fff;
  background-color: #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox .btn1 {
  color: #fff;
  background-color: #f10202;
}

.reviewsec .row .col-md-6:first-child .reviewbox {
  border-bottom: 10px solid #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox {
  border-bottom: 10px solid #f10202;
}

.latestrevsec .form-select {
  width: 150px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 6px 15px;
  background-color: #e3e1e1;
  background-image: url(./../../../../../../assets/images/seclect-arrow2.png) !important;
}

.slick-prev::before {
  content: "";
  background-image: url(./../../../../../../assets/images/Arrow-left.svg);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.slick-next::before {
  content: "";
  background-image: url(./../../../../../../assets/images/Arrow-right.svg);
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.slick-next {
  right: 40%;
  bottom: -55px !important;
  top: inherit !important;
}

.slick-prev {
  left: 40%;
  bottom: -55px !important;
  top: inherit !important;
}

.boxheight {
  height: 70vh;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background: #ccc;
}

::-webkit-scrollbar-thumb {
  border-radius: 00px;
  background: #ff7c00;
  transition: all 0.8s;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff7c00;
}

.overflohidden {
  overflow: hidden;
}

.social-media-prediction .stepbox {
  font-size: 20px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 0px 0px 0px 5px;
  width: 110px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: flex-end;
}

.stepboxpositive {
  color: #222;
  background-color: #9ce9bd;
}

.stepboxpositive span.step-number {
  color: #fff;
  background-color: #219653;
  border-radius: 50%;
  padding: 0 5px;
}

.text-orange {
  color: #ff7c00;
}

.stepboxnegative {
  color: #222;
  background-color: #ffc9c9;
}

.stepboxnegative span.step-number {
  color: #fff;
  background-color: #f00404;
  border-radius: 50%;
  padding: 0 5px;
}

.social-media-prediction .stepbox span {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.Keywordslist {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Keywordslist li {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 5px 15px;
  color: #4b4a4a;
  margin-right: 5px;
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.lighorangebg {
  background-color: #ff7b003f;
}

.lightredbg {
  background-color: #ffc9c9;
}

.ReviwContent {
  border: 1px solid #ff7c00;
}

.form-control {
  border-color: #ff7c00;
  opacity: 1;
}

.ReviwContent p {
  padding-bottom: 0;
  font-size: 17px;
}

.reviewboximg span img {
  min-width: 65px;
  min-height: 65px;
  max-width: 65px;
  max-height: 65px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
}

.ratings {
  font-size: 16px;
}

.color-yellow {
  color: #ffbb00;
}

.color-light {
  color: #ccc;
}

.reviewbox p {
  font-size: 17px;
  line-height: 1.4;
}

.reviewboximglist span img {
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  object-fit: cover;
  position: relative;
}

.reviewplatfrm img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 3px solid #fff;
  object-fit: cover;
}

.reviewplatfrm {
  position: absolute;
  right: -9px;
  bottom: -1px;
}

.border-orange {
  border-color: #ff7c00 !important;
}

.addtitle {
  padding: 10px;
  background-color: #ff7c00;
  width: 90px;
  text-align: center;
  border-radius: 0px 2px 0 15px;
  -webkit-border-radius: 0px 2px 0 15px;
  -moz-border-radius: 0px 2px 0 15px;
  -ms-border-radius: 0px 2px 0 15px;
  -o-border-radius: 0px 2px 0 15px;
}

.addtitle2 {
  padding: 10px;
  background-color: #ff7c00;
  width: 90px;
  text-align: center;
  border-radius: 5px 20px 0 0px;
  -webkit-border-radius: 5px 20px 0 0px;
  -moz-border-radius: 5px 20px 0 0px;
  -ms-border-radius: 5px 20px 0 0px;
  -o-border-radius: 5px 20px 0 0px;
}

.addtitle h4,
.addtitle2 h4 {
  color: #fff;
}

.upload__inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload__btn .form-control {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}

.upload__btn {
  display: inline-block;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: #ff7c00;
  border-radius: 10px;
  line-height: 26px;
  font-size: 17px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border: 0;
}

.upload__btn:hover {
  background-color: #ca680c;
  color: #fff;
  transition: all 0.3s ease;
}

.upload__btn-box {
  margin-bottom: 10px;
}

.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.upload__img-box {
  width: 200px;
  padding: 0 10px;
  margin-bottom: 12px;
}

.upload__img-close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
  line-height: 28px;
  z-index: 1;
  cursor: pointer;
}

.upload__img-close:after {
  content: "\2716";
  font-size: 14px;
  color: white;
}

.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}

.ortxt {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  background-color: #ff7b003f;
  font-size: 25px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ortxt::after {
  border-top: 4px dotted #ff7b003f;
  content: "";
  position: absolute;
  left: 45px;
  width: 150px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.ortxt::before {
  border-top: 4px dotted #ff7b003f;
  content: "";
  position: absolute;
  right: 45px;
  width: 150px;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.grid {
  column-count: 4;
  column-gap: 0.5em;
  max-width: 980px;
  margin: auto;
  width: 100%;
}

.grid__item {
  margin-bottom: 5px;
  cursor: pointer;
}

img {
  height: auto;
  max-width: 100%;
}

.image-checkbox.image-checkbox-checked,
.image-checkbox {
  position: relative;
}

.social-media-result .form-control {
  height: 100% !important;
}

.ai-btn-clear {
  background: transparent;
  border: none;
}

@media (max-width: 800px) {
  .grid {
    column-count: 3;
  }
}

@media (max-width: 600px) {
  .grid {
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .grid {
    column-count: 1;
  }
}

.nopad {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/*image gallery*/
.image-checkbox {
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 4px solid transparent;
  margin-bottom: 0;
  outline: 0;
}

.image-checkbox input[type="checkbox"] {
  display: none;
}

.image-checkbox-checked {
  border-color: #ff7c00;
}

.image-checkbox .fa {
  position: absolute;
  background-color: #ff7c00;
  padding: 2px;
  top: 0;
  right: 0;
  display: none;
}

.image-checkbox-checked .fa {
  display: block !important;
}

.ReviwContent p {
  padding-bottom: 0;
  font-size: 17px;
}

.reviewbox {
  font-size: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.reviewsec .row .col-md-6:first-child .reviewbox h4 {
  color: #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox h4 {
  color: #f10202;
}

.reviewsec .row .col-md-6:first-child .reviewbox .btn1 {
  color: #fff;
  background-color: #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox .btn1 {
  color: #fff;
  background-color: #f10202;
}

.reviewsec .row .col-md-6:first-child .reviewbox {
  border-bottom: 10px solid #219653;
}

.reviewsec .row .col-md-6:last-child .reviewbox {
  border-bottom: 10px solid #f10202;
}

.reviewdtls {
  gap: 8px;
}

/* ========== media Query ==========  */

@media (min-width: 768px) and (max-width: 991.98px) {
  .ReviwContent p {
    padding-bottom: 0;
    font-size: 18px;
  }

  .modal-dialog {
    max-width: 100%;
  }

  .header.header .form-control {
    padding: 8px 4px;
    font-size: 12px;
  }

  .searchbar .form-control {
    border-width: 3px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    font-size: 16px;
  }

  h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 13px;
  }

  .logo {
    text-align: center;
    margin-bottom: 10px;
  }

  .header.header .form-select {
    margin-bottom: 15px;
  }

  .header.header .form-control {
    padding: 8px 4px;
    font-size: 14px;
  }

  .searchbar .form-control {
    border-width: 3px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    font-size: 16px;
  }

  .form-control {
    height: auto;
    padding: 6px 6px;
    font-size: 14px;
  }

  .reviewsec .row .col-md-6:last-child .reviewbox {
    margin-top: 30px;
  }

  h3 {
    font-size: 16px;
  }

  .Keywordslist li {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    padding: 5px 15px;
    color: #4b4a4a;
    margin-right: 5px;
    font-size: 12px;
    margin-bottom: 10px;
  }
}

/********************************** Popeyes ******************************************/
